@import "../../styles/shared";

.subHeader {
  padding: 0 0 8px 4px;

  & ~ & {
    margin-top: 24px;
  }
}

.mobileDragPoint {
  position: absolute;
}

.mobileOnly {
  @include respond-from(tablet) {
    display: none;
  }
}
