@import "../../styles/variables";
@import "../../styles/mixins";

.toast {
  position: relative;
  padding: 16px;
  border-radius: 4px;
  color: $color-white;
  background-color: var(--toast-bg);
  backdrop-filter: blur(48px);

  @include shadow--button;

  z-index: 0;
  display: flex;
  align-items: center;
  user-select: none;

  &__icon {
    margin-right: 8px;
  }

  &__spinner {
    margin: -8px 8px -8px -8px;
    width: 24px;
    height: 24px;
  }
}

.toast--info {
  --toast-bg: #{$color-secondary--80};
}

.toast--warning {
  --toast-bg: #{$color-secondary--80};
}

.toast--error {
  --toast-bg: #{$color-secondary--80};
}

.toast--with-arrow {
  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 7px solid var(--toast-bg);

    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
