@import "../../styles/variables";
@import "../../styles/shared";

.root {
  padding: 1rem 1rem 1rem 24px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-around;
  background-color: $color-white;
  border-radius: 8px 8px 0 0;
}

.separator {
  background: $color-gray-light-light;
  height: 1rem;
  width: 1px;
  border: none;
  display: block;
  align-self: center;
  border-radius: 1px;
  margin-left: auto;
}
