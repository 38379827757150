@import '../../styles/variables';
$templatePaddingHorizontal: 50px;
$templatePaddingVertical: 50px;
$transitionDuration: 0.4s;

.templateSelections {
  padding: $templatePaddingVertical $templatePaddingHorizontal;
  height: 500px;
}

.templateSelections__logo {
  width: 48px;
  position: absolute;
  left: calc(50% - 28px);
  top: 70px;
}

.templateSelections__navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;
}

.templateSelections__navigationItem {
  height: 100%;
  width: 25%;
  overflow: hidden;
  transition: width $transitionDuration ease-in-out;
  border: none;
  padding: 0;
}

.templateSelections__navigationItemImage {
  height: 100%;
  width: 100%;
}

.templateSelections__navigationItem:hover {
  cursor: pointer;
  width: 30%;
}

.templateSelections__navigationItemText {
  position: absolute;
  transform: translateY(-100px);
  width: calc((100% - 2 * #{$templatePaddingHorizontal}) / 4);
  transition: all $transitionDuration ease-in-out;
  color: $color-white;
  text-align: center;
}

.templateSelections__navigationItem:hover .templateSelections__navigationItemText {
  width: calc((100% - 1 * #{$templatePaddingHorizontal}) * 0.27);
  transform: translateY(-150px);
}
