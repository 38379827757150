@import "../../../../styles/shared";

.wallLocation__section {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  @include respond-from(tablet-small) {
    padding: 2.5rem;
  }
}
