.root {
  height: 100vh;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header::after{ 
  content: ''; 
  width: 32px;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 1040px) {
    flex-direction: row;
  }
}

.textWrapper {
  max-width: 249px;
}

.placeholderText {
  margin: 1rem 0 2rem 0;
}

.icon {
  background: url(icons/icon-empty-s.svg);
  width: 128px;
  height: 128px;
  @media screen and (min-width: 1040px) {
    background: url(icons/icon-empty-m.svg);
    width: 256px;
    height: 256px;
  }
}

.footer {
  display: flex;
  justify-content: center;
}