@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  background-color: $color-secondary--4;
  border-radius: 0.25rem;
}

.root.noBackground {
  background-color: transparent;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;

  //overflow: auto;
  //overscroll-behavior-x: contain;
}

.item {
  flex: 1;
}

.root.vertical .list {
  flex-direction: column;
}
