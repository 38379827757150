.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  display: block;
  fill: currentColor;
}

.auto {
  width: 100%;
  height: 100%;
}

.xsmall {
  width: 16px;
  height: 16px;
}

.small {
  width: 24px;
  height: 24px;
}

.medium {
  width: 32px;
  height: 32px;
}


