@import "../../styles/variables";

.wrapper {
  display: grid;
  grid-template-columns: 56px 1fr 56px;
  align-items: center;
  justify-items: center;
}

.prevButtonWrapper {
  transition-property: opacity;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;

  &.prevButtonHidden {
    opacity: 0;
    pointer-events: none;
  }
}

.step {
  border: none;
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  background: none;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    border-radius: 50%;
    border: 1px solid $color-accent--48;
    width: 12px;
    height: 12px;
    position: absolute;
    opacity: 0;
    transform: scale(0.1);

    transition-property: transform, opacity;
    transition-duration: $duration--short;
    transition-timing-function: $easing--decelerated;
  }

  &:after {
    display: block;
    content: "";
    border-radius: 50%;
    background-color: $color-accent--48;
    width: 8px;
    height: 8px;

    transition-property: background-color;
    transition-duration: $duration--short;
    transition-timing-function: $easing--decelerated;
  }

  &.passed {
    &:after {
      transform: scale(1);
      background-color: $color-accent;
    }
  }

  &.current {
    &:before {
      transform: scale(1);
      opacity: 1;
    }

    &:after {
      background-color: $color-accent--48;
    }
  }

  &.disabled {
    &:after {
      background-color: $color-secondary--48;
    }
  }
}
