@import './../../styles/variables';

/* by default font-weight is 400, font-size is 21px, line-height is 25px */

.typography {
  font-size: $font-size-m;
  line-height: 1.375rem;
  text-overflow: ellipsis;
  margin: 0;

  &_size-12 {
    font-size: $font-size-xs;
  }

  &_size-14 {
    font-size: $font-size-s;
  }

  &_size-16 {
    font-size: $font-size-m;
  }

  &_size-18 {
    font-size: $font-size-l;
  }

  &_size-28 {
    font-size: $font-size-xxl;
  }

  &_color-default {
    color: $color-text;
  }

  &_color-white {
    color: $color-white;
  }

  &_color-inherit {
    color: inherit;
  }

  &_color-primary {
    color: $color-primary;
  }

  &_color-error {
    color: $color-error;
  }

  &_align-center {
    text-align: center;
  }

  &_text-transform-uppercase {
    text-transform: uppercase;
  }

  &_text-transform-capitalize {
    text-transform: capitalize;
  }
}
// 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button'
.typography--variant-h1 {
  font-size: $font-size-xxl;
  line-height: 2.5rem;
  font-weight: 700;
}

.typography--variant-h2 {
  font-size: $font-size-xl;
  line-height: 2rem;
  font-weight: 600;
}

.typography--variant-h3 {
  font-size: $font-size-l;
  line-height: 2.5rem;
}

.typography--variant-h4 {
  font-size: $font-size-m;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.typography--variant-subtitle1 {
  font-size: $font-size-m;
  line-height: 1.5rem;
  font-weight: 600;
}

.typography--variant-subtitle2 {
  font-size: $font-size-s;
  line-height: 1.3125rem;
  font-weight: 600;
}

.typography--variant-body1 {
  font-size: $font-size-m;
  line-height: 1.5rem;
  font-weight: normal;
}

.typography--variant-body2 {
  font-size: $font-size-s;
  line-height: 1.3125rem;
  font-weight: normal;
}

.typography--variant-caption {
  font-size: $font-size-xs;
  line-height: 1rem;
}

.typography--variant-button {
  font-size: $font-size-s;
  line-height: 1.25rem;
  font-weight: 700;
}

.typography--variant-link1 {
  font-size: $font-size-s;
  line-height: $font-size-xl;
  //color: $color-additional-dark;
}

.typography--variant-link2 {
  font-size: $font-size-xs;
  line-height: $font-size-l;
  //color: $color-additional-dark;
}


.typography--weight-light {
  font-weight: 300;
}

.typography--weight-semi-bold {
  font-weight: 600;
}

.typography--weight-bold {
  font-weight: 700;
}

.typography--underline {
  text-decoration: underline;
}
