@import './../../styles/variables';
@import './../../styles/shared';

.overlay {
  z-index: $z-index--sidebar-overlay;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.arCode {
  background-color: $color-white;
  padding: 16px;
  border-radius: 4px;

  transform: translateX(-50%);
  left: 50%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 7px solid $color-white;

    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include respond-from(tablet) {
    flex-direction: row;
    align-items: center;
  }
}

.unsupported {
  width: 150px;
  flex-shrink: 0;
  padding: 0 8px 8px;

  a {
    color: $color-secondary;
    font-weight: 600;
    text-decoration: underline 1px $color-accent;
  }

  @include respond-from(tablet) {
    padding: 8px 8px 8px 0;
  }
}

.qrWrapper {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}

.qr {
  animation: appear 250ms $easing--standard forwards;
}
