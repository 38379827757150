@import "../../styles/variables";
@import "../../styles/shared";
@import "../../styles/mixins";

.root {
  height: 100%;
}

.input {
  @include visually-hidden
}

.label {
  height: 100%;
  display: block;
  position: relative;
  color: $color-secondary;

  padding: 0.25rem;

  transition-property: box-shadow;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
}

.label:after {
  box-sizing: border-box;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $color-accent;

  border-radius: 0.25rem;
  pointer-events: none;

  opacity: 0;

  transition-property: opacity, filter;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}

.content {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  border-radius: 0.125rem;
  padding: 1rem;
}

.icon .content {
  padding: 0.75rem;
}

.selected .content {
  background: $color-primary;
}

.title_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  transition: color $duration--short $easing--decelerated;
}

.icon {
  fill-opacity: 0.8;
  transition: color $duration--standard $easing--decelerated;
}

.root.selected, .root:focus-within {
  .icon {
    opacity: 1;
  }

  .label {
    @include shadow--button;
  }

  .label:after {
    opacity: 1;
  }
}

.root:hover:not(.disabled) {
  .label {
    cursor: pointer;
    color: $color-black;
  }

  .label:after {
    border: 1px solid $color-accent--48;
    opacity: 1;
  }
}

.disabled {
  .icon {
    fill-opacity: 0.24;
    box-shadow: none;
  }

  .title_wrapper {
    color: $color-secondary--24;
  }
}

@include respond-from(tablet-small) {
  .root {
  }

  .content {
    flex-direction: column;
  }

  .root:focus-within {
    .label:after {
      @include shadow--light-1-filter;
    }
  }
}
