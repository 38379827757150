@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/shared';

.root {
  padding: 20px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;

  @include respond-to(mobile) {
    width: 100%;
    min-width: min-content;
  }
}

.root .button {
  padding: 1rem;
}
