.notification-container {
  display: flex;
  justify-content: center;
  max-width: 338px;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}
