@import "../../styles/variables";

.textControl {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.textControl + .textControl {
  margin-top: 1.5rem;
}

.caption {
  color: $color-secondary--48;
}

.personalizationTechnic {
  margin-bottom: 1rem;
}
