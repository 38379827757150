@import "../../styles/variables";
@import "../../styles/shared";
@import "../../styles/mixins";

.root {
  position: relative;
}

.input {
  top: 0;
  left: 0;
  @include visually-hidden
}

.label {
  display: block;
  position: relative;

  padding: 0.25rem;
}

.label:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $color-accent;

  border-radius: 0.25rem;
  pointer-events: none;

  opacity: 0;

  transition-property: opacity, filter;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  border-radius: 0.125rem;
  background: $color-primary;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;

  @include respond-from(tablet-small) {
    padding: 0;
  }
}

.title_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 0.25rem;
  padding: 0 0.375rem;
}

.icon {
  fill-opacity: 0.8;
  background: $color-primary;
  border-radius: 0.25rem;
  width: $button-size-s;
  height: $button-size-s;
  transition-property: color, fill-opacity;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}

.root.selected, .root:focus-within {
  .icon {
    color: $color-accent;
    opacity: 1;
    @include respond-from(tablet-small) {
      @include shadow--light-2;
    }
  }

  .label:after {
    opacity: 1;
  }

  .content {
    @include respond-to(tablet-small) {
      @include shadow--light-2;
    }
  }
}

.root:hover:not(.disabled) {
  .label {
    cursor: pointer;
  }

  .label:after {
    border: 1px solid $color-accent--48;
    opacity: 1;
    @include shadow--light-1-filter;
  }
}

.disabled {
  .icon {
    fill-opacity: 0.24;
    box-shadow: none;
  }

  .title_wrapper {
    color: $color-secondary--24;
  }
}

@include respond-to(tablet-small) {
  .content {
    @include shadow--icon;
  }
}

@include respond-from(tablet-small) {
  .root {
    width: 8.75rem;
  }

  .label {
    flex-direction: column;
    padding: 0;
    background-color: transparent;
  }

  .label:after {
    border-radius: 0.5rem;
    height: 8.75rem;
  }

  .content {
    flex-direction: column;
    gap: 1.125rem;
    background: none;
  }

  .icon {
    border-radius: 0.25rem;
    margin: 0.375rem;
    width: $button-size;
    height: $button-size;
    @include shadow--icon;
  }

  .title {
    text-align: center;
  }

  .subtitle {
    display: none;
  }

  .root:focus-within {
    .icon {
      @include shadow--light-2;
    }

    .label:after {
      @include shadow--light-1-filter;
    }
  }
}
