@import './../../styles/shared';

.option {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.5rem;
  width: 100%;
}

.optionOneLine {
  justify-content: space-between;
  align-items: center;

  @include respond-from(tablet-small) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 2.5rem;
  }
}

.optionValues {
  @include respond-from(tablet-small) {
    flex: 1;
  }
}

.mountingType__section {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  @include respond-from(tablet-small) {
    padding: 2.5rem;
  }
}

.boxisOrInterna {
  align-items: flex-start;
}

.mountingType__selector {
  align-self: center;
  margin-top: 1.5rem;
  width: 100%;
  justify-content: center;
  display: flex;
}

.selectorList {
  @include respond-from(tablet-small) {
    @include noScrollbar;
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }
}
