@import "../../styles/variables";
@import '../../styles/shared';
@import "../../styles/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: $z-index--modal;
  overflow: hidden;
  padding: 0;
  height: 100%;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $color-black--48;
}

.root {
  max-width: $modal-width;
  min-width: $modal-width;
  max-height: 100%;
  z-index: calc(#{$z-index--modal} + 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-white;
  border-radius: 8px;
  height: fit-content;

  box-shadow: 0 0 24px $color-black--8;
}

.title {
  text-transform: uppercase;
}

.header {
  flex: 0 1;
  padding: 8px 8px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header::after {
  width: calc(100% - 48px);
  border-bottom: 1px solid $color-secondary;
  content: "";
  position: absolute;
  bottom: 0;
}

.content {
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @extend %scrollbar;
}

.footer {
  border-top: 1px solid $color-gray-244;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex: 0;
}
