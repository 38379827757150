@import '../../styles/variables';
@import '../../styles/mixins';

.inline {
  position: relative;
  display: inline-flex;
  align-self: center;
}

.toggle__input {
  position: absolute;
  opacity: 0;
}

.toggle__label {
  position: relative;
  display: block;
  width: 3rem;
  height: 1.75rem;
  border-radius: 2rem;
  background-color: $color-gray-light;
  transition-property: background-color, box-shadow, border;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
  cursor: pointer;
  user-select: none;

  @include shadow--inner-2;

  &::after {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: $color-white;
    transition-property: transform;
    transition-duration: $duration--short;
    transition-timing-function: $easing--decelerated;
    box-shadow: 0 4px 6px 0 $color-secondary--24;
  }
}

.toggle--disabled .toggle__label {
  opacity: 0.24;
  cursor: default;
}

.toggle__input:checked + .toggle__label {
  background-color: $color-accent;
  border-color: transparent;

  &::after {
    transform: translateX(calc(3rem - 100% - 0.25rem * 2));
  }
}
