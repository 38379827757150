@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/shared';

.root {
  padding: 16px;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 16px;
  align-items: center;
  justify-content: space-around;
  background-color: $color-white;
  border-radius: 8px 8px 0 0;
  width: 100%;
  min-width: min-content;

  @include respond-from(tablet-small) {
    width: min-content;
    min-width: auto;
  }
}

.singleButton {
  position: absolute;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: transform $duration--standard $easing--decelerated;

  --icon-accent: currentColor;
}

.singleButtonAccent {
  --icon-accent: #{$color-accent};
}

.wrapper {
  position: relative;
  height: 56px;
  width: 56px;
}

.selected {
  border: 1px solid $color-accent;
  --icon-accent: #{$color-accent};
}
.hintWrapper {

}
.separator {
  background: $color-gray-light-light;
  height: 1rem;
  width: 1px;
  border: none;
  align-self: center;
  border-radius: 1px;
}

.hint {
  display: none;

  @include respond-from(tablet-small) {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.desktopOnly {
  display: none;

  @include respond-from(tablet) {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}