@import "../../styles/variables";

.root {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  background-color: $color-white;
  transition-property: height, background-color;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
  border-bottom: 1px solid $color-gray-244;

  &:not(.disabled):hover,
  &:not(.disabled):active,
  &:not(.disabled):focus {
    outline: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.disabled {
}

.icon {
  display: inline-flex;
  height: 1em;
  width: 10px;
  color: $color-accent;
  margin-left: -2px;
  margin-right: 2px;
}
