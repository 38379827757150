@import '../../styles/variables';
@import '../../styles/shared';
@import '../../styles/mixins';

.root {
  margin: 16px;
}

.topMenuWrapper {
  display: flex;
  align-items: center;
  background-color: $color-white;
  border-radius: 0.25rem;

  @include respond-to(mobile) {
    width: 100%;
    min-width: min-content;
    justify-content: space-between;
  }
}

.separator {
  background: $color-gray-light-light;
  height: 1rem;
  width: 1px;
  border: none;
  display: block;
  align-self: center;
  border-radius: 1px;
}

.arCode {
  position: absolute;
  z-index: $z-index--dialog;
  opacity: 0;

  transition-property: transform, opacity;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;

  @include respond-to(mobile) {
    transform: none;
    right: 0;
    left: auto;
    &::after {
      left: 84.5%;
    }
  }

  @include shadow--modal-filter;
}

.arWrapper {
  position: relative;
}

.arCodeVisible {
  opacity: 1;
}

.dropDownMenu {
  background-color: $color-white;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  margin-top: 4px;

  opacity: 0;
  display: none;

  @include respond-to(mobile) {
    opacity: 1;
    display: block;
  }
}

.moreActions {
  opacity: 0;
  display: none;
  position: relative;

  @include respond-to(mobile) {
    opacity: 1;
    display: block;
  }
}

.hidden {
  @include respond-to(mobile) {
    opacity: 0;
    display: none;
  }
}

.dropButton {
  min-width: 135px;
}

.btnWrapper {
  display: flex;
  flex-direction: row;
}