@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  transition: transform $duration--standard $easing--decelerated;

  &__step2 {
    transform: translateX(-376px);
  }

  &__step3 {
    transform: translateX(-752px);
  }
}

.stepFormTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.stepFormTitle_subtitle {
  margin-left: 16px;
}

.footerBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerBtn > :first-child {
  margin-right: 8px;
}

.stepForm {
  margin-right: 24px;
  width: 352px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textField {
  //margin-bottom: 24px;
}

.postal {
  display: flex;
  flex-direction: row;
}

.postal_No {
  margin-right: 24px;
  width: 40%;
}

.multiTextField {
  resize: none;
}

.multiTextFieldWrapper {
  height: 100%;
  max-height: 372px;
}

.submittingHint {
  display: flex;
  flex-direction: row;
}

.submittingHint > *:first-child {
  margin-right: 4px;
}

.link:visited, .link:hover, .link:active {
  color: $color-gray;
}

.shake {
  animation-duration: $duration--standard;
  animation-fill-mode: both;
  animation-name: horizontal-shaking;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}
