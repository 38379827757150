@import "variables";

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@mixin no-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

%scrollbar {
  scrollbar-width: thin;
  //scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    height: 0.25rem;
    width: 0.25rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.625rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background-color: $color-secondary--24;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-secondary;
  }
}

@mixin shadow--light-1 {
  box-shadow: 0 2px 4px 0 $color-accent--48;
}

@mixin shadow--light-1-filter {
  filter: drop-shadow(0 2px 4px $color-accent--48);
}

@mixin shadow--light-2 {
  box-shadow: 0 8px 24px 0 $color-accent--24;
}

@mixin shadow--inner-1 {
  box-shadow: 0 -4px 12px 0 $color-black--8 inset;
}

@mixin shadow--inner-2 {
  box-shadow: 0 4px 4px 0 $color-black--8 inset;
}

@mixin shadow--button {
  box-shadow: 0 8px 16px $color-black--8;
}

@mixin shadow--modal {
  box-shadow: 0 0 24px $color-black--8;
}

@mixin shadow--modal-filter {
  filter: drop-shadow(0 0 24px $color-black--8);
}

@mixin shadow--icon {
  box-shadow: 0 8px 24px $color-secondary--4;
}
