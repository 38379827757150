@import "../../styles/variables";


.autocomplete {
  position: relative;
}

.autocomplete_inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.iconContainer {
  flex-shrink: 0;
}

.autocomplete__input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.0625rem solid $color-gray-light-light;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: $color-white;
  color: $color-gray;
  transition-property: border-color, box-shadow, color;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;

  font-size: $font-size-m;
  line-height: 1.3125rem;
  width: 100%;
  text-align: start;
  padding: 16px;

  &:hover {
    border-color: $color-accent--48;
  }

  &:focus-within {
    border-color: $color-accent;
  }

  &:disabled {
    border-color: $color-black--8;
    color: $color-secondary--24;
  }
}

.input_placeholder {
  color: $color-gray-medium
}

.autocomplete__suggestions {
  position: absolute;
  width: 100%;
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 4px 6px $color-black--8;
  z-index: $z-index--overlay;
  background-color: $color-white;
  padding: 0;
  

  &.upwardOpening {
    transform: translateY(-100%);
  }
}



.autocomplete__suggestionsHidden {
  display: none;
}

.autocomplete__suggestion {
  padding: 8px 8px 8px 28px;
  background-color: transparent;
  color: $color-secondary;
  justify-content: start;
}

.autocomplete__suggestion:hover {
  background-color: $color-secondary--4;
  color: $color-black;
}

.selectedItem {
  padding-left: 12px;
  color: $color-black;
}
