
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 96px;
}

.loaderWrapper {
  width: 300px;
  height: 300px;
}