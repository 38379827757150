@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/shared';

.menuContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.image {
  height: 100px;
  width: 100px;
  margin: 0;
  align-self: center;
}

.note {

}

@include respond-from(tablet-small) {
  .menuContent {
    width: 272px;
  }
}