@import "../../styles/variables";
@import "../../styles/mixins";

.wrapper {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.body {
  flex: 1;
  overflow: auto;
  @extend %scrollbar;
}

.line {
  margin-bottom: 1.5rem;
  display: grid;
  align-items: start;
}

.threeColumnsLine {
  //gap: 0.5rem;
  grid-template-columns: 1fr 4ch;
  grid-auto-columns: 10ch;
  grid-auto-flow: column;
}

.twoColumnsLine {
  grid-template-columns: 4fr 3fr;
}

.checkBoxLine {
  grid-template-columns: 5fr 1fr 3fr;
}

.linkLine {
  margin-bottom: 0.0rem;
}
.downloadLink {
  padding: 0;
}

.downloadItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-m;
  font-weight: 400;
}

.price, .count, .dimensionValue {
  text-align: right;
  color: $color-black--48;
}

.footer {
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
