@import "../../styles/variables";

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 0;
  max-height: 56px;
  transition: max-height $duration--standard $easing--decelerated,
              margin-top $duration--standard $easing--decelerated;

  &:visited {
    color: currentColor;
  }

  &:not(.hidden) + & {
    margin-top: 8px;
  }

  &.hidden {
    height: 56px;
    max-height: 0;
    pointer-events: none;
  }

  &.disabled {
    cursor: unset;
  }
}

.step {
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 16px 0;
  color: $color-secondary;
  opacity: 1;
  transition: opacity $duration--standard $easing--standard;

  &.hidden {
    position: absolute;
    opacity: 0;
  }
}

.stepNumber {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $color-accent--48;
  transition: background-color $duration--short $easing--standard,
              color $duration--short $easing--standard;

  &:after {
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 1px solid $color-accent--48;
    border-radius: 50%;
    left: -4px;
    top: -4px;
    transform: scale(0.1);
    transition: transform $duration--standard $easing--decelerated;
  }

  &.active {
    &:after {
      transform: scale(1);
    }
  }

  &.passed {
    background: $color-accent;
  }

  &.disabled {
    color: $color-white;
    background-color: $color-secondary--24;
  }
}

.stepDigit {
  position: absolute;
  opacity: 1;
  transition: opacity $duration--short $easing--standard;

  &.hidden {
    opacity: 0;
  }
}

.stepName {
  position: relative;
  transition: color $duration--short $easing--standard;

  &:after {
    content: "";
    height: 1px;
    background-color: $color-accent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateY(-10px) scaleY(0.1);
    transition-property: opacity, transform;
    transition-duration: $duration--standard;
    transition-timing-function: $easing--decelerated;
  }

  &.active {
    &:after {
      opacity: 1;
      transform: translateY(5px) scaleX(1);
    }
  }

  &.disabled {
    color: $color-secondary--64;
  }
}

.step:not(.disabled):hover, .step.active {
  .stepName {
    color: $color-black;
  }
}
