@import "../../styles/variables";

.wrapper {
  display: grid;
  grid-template-columns: 56px 1fr 56px;
  align-items: center;
  justify-items: center;
}

.prevButtonWrapper {
  transition-property: opacity;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;

  &.prevButtonHidden {
    opacity: 0;
    pointer-events: none;
  }
}

.step {
  border: none;
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  background: none;
  cursor: pointer;

  &:after {
    display: block;
    content: "";
    border-radius: 50%;
    background-color: $color-white--48;
    width: 12px;
    height: 12px;

    transform: scale(0.67);
    transition-property: transform, background-color;
    transition-duration: $duration--short;
    transition-timing-function: $easing--decelerated;
  }

  &:hover {
    &:after {
      transform: scale(1);
    }
  }

  &.current {
    &:after {
      transform: scale(1);
      background-color: $color-white;
    }
  }
}
