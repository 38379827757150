@import '../../styles/variables';

.viewer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  // overlap canvas to be sure touch-scroll works
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.canvas {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  //transition: all ease 0.4s;
}
