@import '../../../../styles/variables';
@import '../../../../styles/shared';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include respond-from(tablet-small) {
    width: auto;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  @include respond-from(tablet-small) {
    padding: 0;
  }
}

.header {
  text-align: center;
}

.countryInput {
  align-self: center;
  width: 100%;
  min-width: 300px;

  @include respond-from(tablet-small) {
    padding: 0 0.5rem;
  }
}

.countryInput::placeholder {
  font-size: $font-size-s;
}
