@import '../../styles/shared';

.layoutForm__selector {
  align-self: center;
  margin-top: 1.5rem;
  width: 100%;
  justify-content: center;
  display: flex;
}

.selectorList {
  @include respond-from(tablet-small) {
    @include noScrollbar;
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }
}
