@import "../../styles/variables";
@import "../../styles/mixins";


.root {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  overflow: hidden;
}

.colorSidePanelSection {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  transition: $duration--standard;
  transition-property: transform;
  transition-timing-function: $easing--standard;

  overflow-y: auto;
  height: 100%;
  @extend %scrollbar;

  &.opened {
    transform: translateX(-100%);
  }
}

.smooth {
  transition-duration: $duration--standard;
  transition-property: transform;
  transition-timing-function: $easing--in-out;
}

.titleWithIcon {
  display: flex;
  align-items: center;
}

.iconGoBack {
  animation-name: animationGoBackIconSmoothAppear;
  animation-duration: $duration--standard;
  animation-timing-function: $easing--standard;
  margin-left: -16px;
}

@keyframes animationGoBackIconSmoothAppear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.titleWhenPanelOpened {
  margin-left: -40px;
  transform: translateX(48px);
}

.paletteTitle {
  margin-top: 2.5rem;
}

.paletteTitle,
.title {
  margin-bottom: 0.75rem;
}

.paletteSearch {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.colorsList {
  margin-top: 1rem;
}

.paletteDontKnowColor {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
}

.paletteDontKnowColorLabel {
  margin-left: 0.625rem;
}

.color {
  margin-right: 10px;
  padding-right: 8px;
}

.searchAdornment {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: -0.5rem;
}

.searchAdornmentText {
  opacity: 0.48;
  margin-bottom: 1px;
  user-select: none;
}

.clearButton {
  border: none;
  background: none;
  color: $color-black;
  opacity: 0.48;
  cursor: pointer;
  padding: 0;

  transition-property: opacity;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
}

.clearButton:hover {
  opacity: 1;
}

.clearButton[hidden] {
  display: block;
  opacity: 0;
  pointer-events: none;
}

.customColorOpenBtn {
  transition-duration: $duration--standard;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 16px 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;

  color: $color-secondary;
  --icon-accent: #{$color-accent};

  &:hover {
    color: $color-black;
  }

  &:active {
    color: $color-black;
  }
}

.selectCustomColorNote {
  margin-left: auto;
  margin-right: 16px;
  color: $color-black--48;
}

.customColorNote {
  color: $color-secondary;
}