.frames {
  text-align: center;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.image {
  width: 128px;
  height: 128px;
  object-fit: contain;
}

.nav {
  margin: 8px -4px -4px;
}
