@import '../../styles/variables';
@import '../../styles/mixins';

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
}

.checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border: 1px solid $color-gray-light-light;
  background-color: $color-secondary--4;
  transition-property: background-color, box-shadow, border;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
  cursor: pointer;
  user-select: none;

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.0625rem;
    background-color: $color-white;
    transition-property: opacity;
    transition-duration: $duration--short;
    transition-timing-function: $easing--decelerated;
    box-shadow: 0 4px 6px 0 $color-secondary--24;
    opacity: 0;
  }
}

.checkbox--disabled {
  cursor: default;
  opacity: 0.24;
}

.checkbox__input:checked + .checkbox__label {
  background-color: $color-accent;
  border-color: transparent;

  &::after {
    opacity: 1;
  }
}
