@import '../../styles/variables';
@import '../../styles/shared';

.root {
  display: flex;
  height: 100%;
}

.viewer {
  flex: 1 1;
  position: relative;
}

.dragHint, .zoomHint {
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  z-index: 10;
}

.rotateHint {
  position: absolute;
  pointer-events: none;
  bottom: 10%;
  z-index: 10;
  left: 50%;
}

.header {
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.header:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
  width: calc(100% - 5rem);
  margin: 0 2.5rem;
  background-color: $color-gray-light;
}

.cart {
  width: 10.375rem;
  position: relative;
}

.cartIcon {
  position: absolute;
  // sorry for that
  left: 1.5rem;
  top: 15%;
}

.cartLabel {
  padding-left: 1.5rem;
}

.main {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.inline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}