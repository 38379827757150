@import '../../styles/variables';
@import '../../styles/shared';

.countryList {
  margin: 40px 0;
  text-align: center;
  white-space: pre-line;
}

.countryList__country {
  text-align: center;
  padding: 16px 24px;
  border: 1px dashed $color-secondary--24;
  border-radius: 40px;
  cursor: pointer;
}

.countryButton {
  text-align: center;
  margin: 0.5rem;
  display: inline-block;

  input[type=radio] {
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 10px 16px;
    border: 1px dashed $color-secondary--24;
    border-radius: 40px;
    transition: background-color $duration--short $easing--standard;
    background: transparent;

    @include respond-from(tablet-small) {
      padding: 16px 24px;
    }
  }

  label:hover {
    background: $color-black--8;
    cursor: pointer;
  }

  input[type=radio]:checked + label {
    background: $color-accent;
    color: $color-black;
    border-color: $color-accent;
  }
}
