@import "../../styles/shared";
@import "../../styles/mixins";

.root {
}

.list {
  list-style: none;
  padding: 0.375rem 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  overflow: auto;
  overscroll-behavior-x: contain;
}

@include respond-to(tablet-small) {
  .list {
    width: 100%;
    gap: 0.5rem;
  }
}

@include respond-from(tablet-small) {
  .list {
    flex-direction: row;
    gap: 2rem;
  }
}
