@import '../../../../styles/variables';
@import '../../../../styles/shared';

.boxesForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;

  @include respond-from(tablet-small) {
    padding: 2.5rem;
  }
}

.boxisOrInterna {
  align-items: flex-start;
}

.boxesBtn {
  border: 5px solid transparent;
}

.option {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 1.5rem;
  width: 100%;
}

.optionOneLine {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;

  @include respond-from(tablet-small) {
    margin-top: 2.5rem;
  }
}

.optionHidden {
  visibility: hidden;
  height: 0;
  margin-top: 0;
}

.optionField {
  width: 80px;
  flex-shrink: 0;
}

.amountHelpText {
  display: none;
  margin-left: auto;
  margin-right: 16px;
  text-align: end;
}

.modeSelector li{
  @include respond-from(desktop) {
    width: 9.5rem;
  }
}

.ownBoxes {
  margin-right: auto;
}

.dimensions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.border {
  width: 100%;
  border-bottom: 1px solid $color-gray-light;
  margin-top: 2rem;
}

.lockTypeHint {
  visibility: visible;
  opacity: 1;
  max-height: 20px;
  transition: opacity $duration--standard $easing--decelerated $duration--short,
  max-height 600ms $easing--decelerated;
}

.lockTypeHintHidden {
  opacity: 0;
  max-height: 0;
  pointer-events: none;

  transition: opacity $duration--standard $easing--decelerated,
  max-height $duration--standard $easing--decelerated $duration--short;
}

.lightboxImage {
  border-radius: 0.25rem;
  width: $button-size-s;
  height: $button-size-s;
  transition: opacity $duration--standard $easing--decelerated;
}

.disabled {
  .lightboxImage {
    opacity: 0.24;
  }
}

@include respond-from(desktop) {
  .option {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .amountHelpText {
    display: block;
  }
}

@include respond-from(tablet-small) {
  .lightboxImage {
    width: $button-size;
    height: $button-size;
  }
}

.selectorList {
  @include respond-from(tablet-small) {
    @include noScrollbar;
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }
}

.sideSelector {
  align-self: center;
  margin-top: 0;
  width: 100%;
  justify-content: center;
  display: flex;

  @include respond-from(tablet-small) {
    margin-top: 1.5rem;
  }

  @include respond-from(desktop) {
    margin-top: 0;
    width: auto;
  }
}

.sideSelectorList {
  @include respond-from(tablet-small) {
    @include noScrollbar;
    margin: 0 -2.5rem;
    padding: 0 2.25rem;
  }
}

.hintContainer {
  margin-top: 0.25rem;

  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  @include respond-from(desktop) {
    max-width: 16rem;
  }
}
