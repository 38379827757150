@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.collapsed {
  transition: all $duration--standard $easing--standard;
  transform: translateY(100%)
}

.headerContainer {
  position: relative;
  padding-top: 1.5rem;
  touch-action: none;
}

.header:before {
  width: 1.5rem;
  height: 0.25rem;
  border-radius: 0.125rem;
  content: "";
  left: 50%;
  top: 0.625rem;
  transform: translateX(-50%);
  background-color: $color-white--48;
  position: absolute;
}

.header {
  flex: 0;
  padding: 8px 8px 8px 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
}

.header:after {
  width: calc(100% - 48px);
  border-bottom: 1px solid $color-secondary;
  content: "";
  position: absolute;
  bottom: 0;
}

.main {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  @extend %scrollbar;
}

.content {
  padding: 24px;
  overflow-y: auto;
  @extend %scrollbar;
}

.content + .content {
  padding-top: 0;
}

.container {
  padding: 24px;
}

.footer {
  border-top: 1px solid $color-gray-244;
  flex: 0;
}

.contentHeight {
  flex: 0;
  flex-basis: auto;
}

.restHeight {
  flex: 1;
}
