@import "../../styles/variables";
@import "../../styles/mixins";
@import '../../styles/shared';

.root {
}

.input {
  @include visually-hidden
}

.label {
  display: block;
  position: relative;

  padding: 0.25rem;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  background: $color-primary;
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 0.25rem;
}

.icon {
  height: 80px;
  width: 80px;
  fill-opacity: 0.8;
  background: $color-primary;
  transition-property: color, fill-opacity;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}

.desktopOnly .icon {
  touch-action: none;
}

.imageWrapper {
  display: flex;
  border: 1px solid transparent;
  border-radius: 0.125rem;
  padding: 3px;
  touch-action: none;
}

.root:hover:not(.disabled) .imageWrapper {
  border: 1px solid $color-accent--48;
}

.root.selected, .root:focus-within, .root:not(.disabled):active {
  .icon {
    color: $color-accent;
    opacity: 1;
  }

  .label:after {
    opacity: 1;
  }

  .imageWrapper {
    border: 1px solid $color-accent;
  }
}

.root:hover:not(.disabled) {
  .label {
    cursor: pointer;
  }
}

.disabled {
  .icon {
    fill-opacity: 0.24;
  }

  .title_wrapper {
    color: $color-secondary--24;
  }
}

.desktopOnly {
  @media screen and (max-width: $mobile) {
    display: none;
  }
}
.mobileOnly {
  @media screen and (min-width: ($mobile + 1px)) {
    display: none;
  }
}