@import '../../styles/variables';
@import '../../styles/shared';
$animation-duration: 1s;
.root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &.loaded {
    .main, .arrowButton, .footer, .logo {
      opacity: 1;
    }
    .loader {
      opacity: 0;
      transition: opacity ease-in-out $animation-duration;
    }
  }
}

.loader {
  width: 300px;
  height: 300px;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header {
  display: grid;
  grid-template-columns: 56px 1fr 56px;
  justify-items: center;
  padding: 40px 16px;

  @include respond-from(desktop) {
    padding: 40px;
  }
}

.arrowButton, .footer, .main, .logo {
  opacity: 0;
  transition: opacity ease-in-out $animation-duration;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.carouselWrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media screen and (min-width: 1040px) {
    display: block;
  }
}

.info {
  display: flex;
  padding: 0 16px 20px;
  justify-content: center;
  align-items: center;
  opacity: 1;
  max-width: 720px;
  margin: auto;

  @media screen and (min-width: 1040px) {
    position: absolute;
    left: 40px;
    width: 230px;
    height: 100%;
    padding: 0;

    opacity: var(--scroll-opacity);
  }
}

.hint {
  @media screen and (max-width: 1039px){
    font-size: 1rem;
    line-height: 1.5;
  }
}
.slider {
  position: relative;
  height: 100%;
  min-height: 200px;

  --scroll-margin: 16;

  @media screen and (min-width: 760px) {
    --scroll-margin: 32;
  }

  @include respond-from(desktop) {
    --scroll-margin: 370;
  }
}

.frame {
  position: absolute;
  z-index: 5;
  padding: 8px;
  border: 2px solid $color-accent;
  border-radius: 8px;
  box-sizing: content-box;

  width: calc(100% - var(--scroll-margin) * 2 * 1px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  pointer-events: none;

  @include respond-from(desktop) {
    display: block;
    aspect-ratio: 300 / 157;
  }
}

.prevButtonContainer {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 16px), -50%);
  pointer-events: auto;
}

.nextButtonContainer {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 16px), -50%);
  pointer-events: auto;
}

.trackContainer {
  height: 100%;
  position: relative;
}

.carouselTrack {
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;

  overflow-x: auto;
  overscroll-behavior: contain;
  scroll-behavior: auto;
  scroll-snap-type: x mandatory;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  gap: 16px;

  @media screen and (min-width: 760px) {
    gap: 32px;
  }

  @include respond-from(desktop) {
    gap: 100px;
  }
}

.slide {
  cursor: pointer;
  scroll-snap-align: center;
  flex: 0 0 calc(100% - var(--scroll-margin) * 2 * 1px);
  height: 100%;

  transition-property: opacity;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:first-child {
    margin-left: calc(var(--scroll-margin) * 1px);
  }

  &:last-child {
    margin-right: calc(var(--scroll-margin) * 1px);
    position: relative;

    &::after {
      position: absolute;
      content: '';
      right: calc(-1 * var(--scroll-margin) * 1px);
      width: calc(var(--scroll-margin) * 1px);
      height: 100%;
      top: 0;
    }
  }

  @include respond-from(desktop) {
    aspect-ratio: 300 / 157;
    height: auto;

    pointer-events: auto;

    opacity: 0.5;

    &:hover {
      opacity: 1
    }
  }
}

.currentSlide {
  opacity: 1;
}

.carouselNav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselIndicator {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carouselIndicatorDot {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: $color-secondary--24;
  transition-property: transform;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;

  transform: scale(calc(2 / 3));
}

.currentDot {
  background-color: $color-accent;
  transform: scale(1);
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
