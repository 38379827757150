@import "../../styles/variables";
@import "../../styles/shared";

.textFieldLabel {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-right: 60px;
}

.labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  fill-opacity: 0;
  transition: color $duration--standard $easing--decelerated;
  margin-right: 12px;
}

.iconStart {
  align-self: flex-start;
}

.description {
  color: $color-black--32;
}

@include respond-from(desktop) {
  .labelsIconPlaceholder {
    margin-left: 36px;
  }
}
