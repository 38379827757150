.wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.loadingIcon {
  width: 24px;
  height: 24px;
  margin: 86px auto;
}

.mailingIcon {
  width: 128px;
  height: 128px;
  margin: 24px auto;
}