@import '../../styles/variables';
@import '../../styles/shared';

.wizard {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include respond-from(tablet-small) {
    flex-direction: row;
  }
}

.stepsSection {
  grid-area: stepper;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 16px 24px;
  border-top: 1px solid $color-gray-244;
  order: 2;
  width: 100%;

  @include respond-from(tablet-small) {
    order: unset;
    height: 100%;
    border: none;
    align-items: unset;
    flex-direction: column;
    padding: 40px;
    max-width: clamp(300px, 100%, 400px);
    flex: 1 1 100%;
  }
}

.stepsSection__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  height: 100%;
  flex-shrink: 0;

  @include respond-from(tablet-small) {
    flex: 0;
  }
}

.stepsSection__header_logo {
  height: 100%;
  margin-right: 24px;

  @include respond-from(tablet-small) {
    height: 64px;
    margin: 0;
    flex-shrink: 0;
  }
}

.stepsSection__header_text {
  display: none;

  @include respond-from(tablet-small) {
    display: block;
    font-size: clamp(1rem, 2.6vw, 1.75rem);
    line-height: clamp(1.2rem, 4vw, 2.5rem);
  }
}

.stepsSection__desktop {
  margin-bottom: 80px;
  display: none;
  flex: 1;

  @include respond-from(tablet-small) {
    display: block;
  }
}

.stepsSection__mobile {
  display: block;
  flex: 1;

  @include respond-from(tablet-small) {
    display: none;
  }
}

.configurationBlock {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $color-paper;
  flex: 1;

  @include respond-from(tablet-small) {
    min-width: 500px;
  }
}

.pagesWrapper {
  flex: 1;
  position: relative;
}

.configurationBlock__params {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  will-change: transform;

  width: calc(var(--total-steps) * 100%);
  transform: translateX(calc(-100 / var(--total-steps) * var(--stage) * 1%));

  transition: transform $duration--standard $easing--decelerated;
}

.configurationBlock__params_content {
  margin: 0 auto;
  align-self: center;
}

.configurationBlock__controls {
  margin: 0 1.25rem;
  max-height: 88px;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid $color-secondary;
  display: none;
  padding: 1rem;
  max-width: min(45.75rem, calc(100% - 2.5rem));
  width: 100%;
  align-self: center;

  a {
    text-decoration: none;
  }

  @include respond-from(tablet-small) {
    display: flex;
    margin-bottom: 0.5rem;
  }
}

.navButton {
  padding: 18px;
  display: flex;
  justify-content: center;
}

.navButtonContent {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.stepContainer {
  position: relative;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0;
  overflow-y: auto;
}

.stepContainerScrollableCurrent {
  > *:first-child {
    animation: scrollHint 800ms $easing--standard 300ms;
  }
}

.divider {
  height: 24px;
  width: 1px;
  background-color: $color-gray-light-light;

  @include respond-from(tablet-small) {
    display: none;
  }
}

.stepContainer__inert {
  flex: 1;
  max-width: min(732px, 100%);
}

.stepContainerHint {
  position: absolute;
  bottom: 10px;
  opacity: 0;
  transform: translateY(-50px);
  visibility: hidden;

  transition: transform 150ms $easing--standard, opacity 150ms $easing--standard;
}

.stepContainerHintVisible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition-delay: 500ms;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}
