@import './../../styles/variables';
@import './../../styles/shared';

.intercomForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.intercomForm__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include respond-from(desktop) {
    padding-top: 0;
  }
}

.intercomForm__intercomBtn {
  border: 5px solid transparent;
}

.intercomForm__options {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-around;
}

.intercomForm__option {
  visibility: visible;
  opacity: 1;
  max-height: 700px;
  margin-top: 1.5rem;
  transition: opacity $duration--standard $easing--decelerated $duration--short,
  max-height 600ms $easing--decelerated;
}

.intercomForm__optionBtn {
  margin-right: 34px;
}

.intercomForm__optionBtn:last-child {
  margin-right: 0;
}

.intercomForm__optionName {
  padding-top: 20px;
  text-align: center;
  width: $button-size;
}

.intercomForm__ownIntercom {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  @include respond-from(tablet-small) {
    gap: 2rem;
  }
}

.intercomForm__ownIntercom_dimensions {
  display: flex;
  flex-direction: column;

  .description {
    display: flex;
    flex-direction: column;

    .hint {
      color: $color-black--48;
    }
  }

  @include respond-from(tablet-small) {
    flex-direction: row;
  }
}

.intercomForm__ownIntercom_toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    display: flex;
    flex-direction: column;

    .hint {
      color: $color-black--48;
    }
  }
}

.intercomForm__ownIntercomControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intercomForm__customIntercomName {
  width: 100%;
  background-color: $color-white;
  //margin: 30px 0;
  align-self: flex-start;

  opacity: 1;
  transition-property: opacity;
  transition-duration: $duration--short;
  transition-timing-function: $easing--standard;

  @include respond-from(tablet-small) {
    width: 19rem;
  }
}

.intercomForm__customIntercomDimensions {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  opacity: 1;
  transition: opacity $duration--short $easing--standard;

  @include respond-from(tablet-small) {
    width: 19rem;
    margin-right: 2.5rem;
  }
}

.intercomForm__customIntercomFieldHidden {
  opacity: 0;
}

.intercomForm__customIntercomNoDimensions {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 1rem;

  visibility: visible;
  opacity: 1;
  max-height: 60px;

  transition: opacity $duration--standard $easing--decelerated $duration--short,
  max-height $duration--standard $easing--decelerated;

  @include respond-from(desktop) {
    margin-top: 0;
  }
}

.intercomForm__customIntercomNoDimensionsText {
  margin-left: 1.125rem;
}

.intercomForm__optionHidden {
  opacity: 0;
  max-height: 0;
  pointer-events: none;

  transition: opacity $duration--standard $easing--decelerated,
  max-height $duration--standard $easing--decelerated $duration--short;
}

.intercomForm__settingToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  &.intercomForm__optionHidden {
    margin-bottom: -2rem;
  }

  @media screen and (max-width: $tablet-small) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond-from(tablet-small) {
    gap: 2.5rem;
  }

  .settingToggleTitle {
    margin-bottom: 8px;

    @include respond-from(tablet-small) {
      flex: 1;
    }
  }
}

.intercomForm__cutoutManufacturerToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $tablet-small) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond-from(tablet-small) {
    gap: 2.5rem;
  }

  .settingToggleTitle {
    margin-bottom: 8px;

    @include respond-from(tablet-small) {
      flex: 1;
    }
  }
}

.intercomForm__dimensions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@include respond-from(desktop) {
  .intercomForm__ownIntercomControl {
    gap: 40px;
  }
}

.modeSelector {
  min-width: 204px;
  width: 100%;

  @include respond-from(tablet-small) {
    flex: 1;
    min-width: 13.125rem;
  }
}

.modeSelector li {
}

.locationForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
  padding: 1.5rem;

  @include respond-from(tablet-small) {
    padding: 2.5rem;
  }
}

.dimensionsAdornment {
  color: $color-black--48;
}

.selectorList {
  @include respond-from(tablet-small) {
    @include noScrollbar;
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }
}
