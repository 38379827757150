@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  position: relative;
  height: 4rem;
  padding: 0.25rem;
}

.input {
  @include visually-hidden;
}

.label {
  display: block;
  height: 3.5rem;
  cursor: pointer;
}

.label:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $color-accent;

  border-radius: 0.25rem;
  pointer-events: none;

  opacity: 0;

  transition-property: opacity, filter;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
}

.color {
  width: 100%;
  height: 100%;
  border-radius: 0.125rem;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
}

.colorText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.check {
  opacity: 0;
  transform: translateY(10px);

  transition-property: opacity, transform;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;
}

.selected {
  .check {
    opacity: 1;
    transform: translateY(0);
  }

  .label:after {
    opacity: 1;
  }
}

.root.minimal {
  width: 4rem;
}

.minimal {
  .label {
    width: 3.5rem;
    color: $color-white--48;
  }

  .color {
    padding: 0.5rem 0.5rem 0.25rem;
    flex-direction: column-reverse;
  }
}
