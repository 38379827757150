@import '../../styles/variables';
@import '../../styles/shared';

.menuOverlay {
  display: grid;
  justify-items: center;
  grid-template-areas: "topMenu sidebar"
                      "main sidebar"
                      "notifications sidebar"
                      "mainMenu sidebar";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @include respond-to(tablet-small) {
    grid-template-columns: 1fr;
  }
}

.topMenu {
  grid-area: topMenu;
  pointer-events: all;

  @include respond-to(mobile) {
    width: 100%;
    transition: transform $duration--standard $easing--decelerated;

    &.withSidebar {
      transform: translateY(-100%);
    }
  }
}

.notifications {
  grid-area: notifications;
  pointer-events: none;
  padding-bottom: 16px;
  transition-property: transform;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--decelerated;
  z-index: $z-index--notification;

  &.menuHidden {
    transform: translateY(96px);
  }

  @include respond-to(mobile) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mainMenu {
  grid-area: mainMenu;
  pointer-events: all;

  transition: transform $duration--standard $easing--decelerated,
              visibility 1ms linear $duration--standard;

  @include respond-to(mobile) {
    width: 100%;
  }

  &.hidden {
    transform: translateY(96px);
  }
}

.sidebar {
  grid-area: sidebar;
  pointer-events: all;
  overflow: hidden;

  @include respond-to(tablet-small) {
    grid-column: 1;
  }
}

.sidebarOpened {
  width: $side-panel-width;
  padding-right: 10px;
  animation: slide-up $duration--standard $easing--decelerated;
  transition: transform $duration--standard $easing--decelerated;
  z-index: $z-index--dialog + 1;

  @include respond-to(mobile) {
    padding-right: 0;
    width: 100%;
  }
}

.sidebarCollapsed {
  grid-row: 4;

  @include respond-to(mobile) {
    overflow: visible;
  }
}

.sidebarHidden {
  transition: transform $duration--standard $easing--standard;

  @include respond-to(tablet-small) {
    animation: none;
    transform: translateY(100%);
  }
}
