@import "../../styles/variables";
@import "../../styles/shared";

.root {
  padding: 1rem;
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-around;
  background-color: $color-white;
  border-radius: 8px 8px 0 0;
}

.separator {
  background: $color-gray-light-light;
  height: 1rem;
  width: 1px;
  border: none;
  display: block;
  align-self: center;
  border-radius: 1px;
}

.buttonLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobileLabelText, .screenLabelText {
  margin-left: 1rem;
}

.screenLabelText {
  display: none;
}

.hideOnMobile {
  display: none;
}

@include respond-from(mobile) {
  .mobileLabelText {
    display: none;
  }
  .screenLabelText {
    display: unset;
  }

  .hideOnMobile {
    display: revert;
  }
}
