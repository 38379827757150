@import "../../styles/variables";
@import "../../styles/mixins";

.textField {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  margin-bottom: 8px;
}

.hint {
  font-size: $font-size-xs;
  line-height: 15px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  border: 0.0625rem solid $color-gray-light-light;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: $color-white;
  color: $color-gray;
  transition-property: border-color, box-shadow;
  transition-duration: $duration--short;
  transition-timing-function: $easing--decelerated;

  &:hover {
    border-color: $color-accent--48;
  }

  &:focus-within {
    border-color: $color-accent;
  }

  &Multiline {
    flex-direction: column;
    flex-grow: 1;
  }
}

.textFieldError .inputWrapper  {
  border-color: $color-error;
}

.textFieldDisabled .inputWrapper  {
  border-color: $color-gray-light;
  pointer-events: none;
  color: $color-gray--24;
}

.input {
  flex: 1 1;
  font-size: $font-size-m;
  line-height: 1.313rem;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  padding: 16px;

  &::placeholder {
    color: $color-gray-medium;
  }

  &Multiline {
    flex-grow: 1;
    box-sizing: border-box;

    @include no-scrollbar;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.input:focus {
  outline: 0;
}

.textFieldAdornmentStart .inputWrapper {
  padding-left: 16px;
}

.textFieldAdornmentEnd .inputWrapper {
  padding-right: 8px;
}

.textFieldDisabled .input {
  &::placeholder {
    color: $color-gray-light;
  }
}

.adornment {
  position: static;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-gray-dark;
}

.adornmentStart {
}

.textFieldDisabled .adornmentStart {
  color: $color-gray-light;
}

.adornmentEnd {
}

.errorLabel {
  font-size: $font-size-xs;
  line-height: 15px;
  margin-left: 4px;
  color: $color-error;
}
