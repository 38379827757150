@import "../../styles/shared";

.image {
  height: 128px;
  object-fit: contain;
}

.desktopOnly {
  display: none;

  @include respond-from(tablet-small) {
    display: unset;
  }
}

.mobileOnly {
  display: none;

  @include respond-to(tablet-small) {
    display: unset;
  }
}
