@import "src/styles/mixins";

.root {
  display: flex;
  overflow: hidden;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  gap: 0.5rem;
  overflow: auto;
  overscroll-behavior-x: contain;

  @extend %scrollbar;
}

.vertical {
  flex-direction: column;

  .list {
    flex-direction: column;
  }
}