.container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3.5rem;
}

.block + .block {
  margin-top: 2.5rem;
}

.blockLabel {
  margin-bottom: 0.75rem;
}

.sectionTitle {
  margin-bottom: 1.5rem;
}