@import '../../styles/variables';

.button {
  position: relative;
  border: none;
  padding: 1.125rem 1rem;
  transition-property: background-color, box-shadow, color, opacity;
  transition-duration: $duration--standard;
  transition-timing-function: $easing--standard;
  cursor: pointer;
  isolation: isolate;
  margin: 0;
  appearance: none;

  &[disabled] {
    opacity: 0.24;
    cursor: default;
  }

  &:link {
    color: currentColor;
    text-decoration: none;
  }
}

.button--primary {
  color: $color-secondary;
  background-color: $color-accent;
  border-radius: 0.25rem;

  &:not(.button--disabled):hover {
    color: $color-white;
    background-color: $color-secondary;
  }

  &:not(.button--disabled):active {
    color: $color-white;
    background-color: $color-gray-dark;
  }
}

.button--secondary {
  background-color: transparent;
  border-radius: 0.125rem;
  color: $color-secondary;
  padding: 1rem;

  --icon-accent: #{$color-accent};

  &::after {
    display: block;
    position: absolute;
    margin: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    content: "";
    border-radius: inherit;
    background-color: transparent;

    transition-property: background-color, transform;
    transition-duration: $duration--standard;
    transition-timing-function: $easing--standard;
  }

  &:not(.button--disabled):hover {
    color: $color-black;
  }

  &:not(.button--disabled):active {
    color: $color-black;

    &::after {
      background-color: $color-black--8;
    }
  }

  &.button--active {
    color: $color-white;
    --icon-accent: #{$color-white};

    &::after {
      background-color: $color-secondary;
    }

    &:not(.button--disabled):hover {
      color: $color-white;
    }
  }

  &.button--inverted {
    color: $color-white;
    --icon-accent: #{$color-white};
    opacity: 0.64;

    &::after {
      background-color: transparent;
    }

    &:not(.button--disabled):hover {
      color: $color-white;
      opacity: 1;
    }

    &:not(.button--disabled):active {
      color: $color-white;
      opacity: 1;
    }
  }
}

.button--icon {
  &.button--active {
    outline: 1px solid $color-accent;
  }
}
