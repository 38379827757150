@import '../../styles/variables';
@import '../../styles/shared';

.viewer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.canvas {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

:global(.dimensions) {
  position: absolute;
  top: 0;
  pointer-events: none;
}


@include respond-from(tablet-small) {
  .viewerExpanded {
    .canvas, :global(.dimensions) {
      transform: translateX(-$side-panel-width);
    }
  }
}

.zone {
  position: absolute;
  border: 1px solid black;
}

.canvas, :global(.dimensions) {
  transform: translateX( calc(-#{$side-panel-width} / 2));
  transition: transform ease 0.4s;
}

.mobileDragPoint {
  touch-action: none;
  position: absolute;
  background-color: $color-accent;
  width: 40px;
  height: 40px;
}

.draggingBox {
  touch-action: none;
  position: absolute;
  display: block;
  background-color: $color-accent;
  z-index: $z-index--modal;
}