$color-paper: #fafafa;
$color-white: #fff;
$color-white--30: rgba(255, 255, 255, 0.3);
$color-white--48: rgba(255, 255, 255, 0.48);

$color-black: rgb(0, 0, 0);
$color-black--48: rgba(0, 0, 0, 0.48);
$color-black--8: rgba(0, 0, 0, 0.08);
$color-black--32: rgba(0, 0, 0, 0.32);

$color-gray-244: rgba(244, 244, 244, 1); //#F4F4F4
$color-gray-light: rgba(228, 228, 228, 1); //#E4E4E4
$color-gray-lighter: rgba(66, 77, 88, 1);
$color-gray-lightest: rgba(209, 209, 209, 1);
$color-gray-light-light: rgba(224, 224, 224, 1); //#E0E0E0
$color-gray-medium: rgba(185, 186, 188, 1);
$color-gray-dark: rgba(60, 60, 67, 1);
$color-gray-dark--64: rgba(60, 60, 67, 0.64);
$color-gray: rgba(72, 84, 95, 1);
$color-gray--64: rgba(72, 84, 95, 0.64);
$color-gray--24: rgba(72, 84, 95, 0.24);

$color-primary: white;
$color-secondary: rgba(69, 85, 96); //#455560
$color-secondary--4: rgba(69, 85, 96, 0.04);
$color-secondary--24: rgba(69, 85, 96, 0.24);
$color-secondary--48: rgba(69, 85, 96, 0.48);
$color-secondary--64: rgba(69, 85, 96, 0.64);
$color-secondary--80: rgba(69, 85, 96, 0.8);
$color-accent: rgb(255, 207, 0);
$color-accent--20: rgba(249, 214, 72, 0.2);
$color-accent--24: rgb(255, 207, 0, 0.24);
$color-accent--48: rgb(255, 207, 0, 0.48);
$color-text: $color-gray;
$color-error: rgba(238, 134, 101, 1);

$font-size-default: 16px;
$font-size-xxxl: 2.5rem; /* 40px */
$font-size-xxl: 1.75rem; /* 28px */
$font-size-xl: 1.5rem; /* 24px */
$font-size-l: 1.3125rem; /* 21px */
$font-size-m: 1rem; /* 16px */
$font-size-s: 0.875rem; /* 14px */
$font-size-xs: 0.75rem; /* 12px */
$font-size-xxs: 0.625rem; /* 10px */

/* animation */
$duration--short: 150ms;
$duration--standard: 300ms;
$easing--standard: cubic-bezier(0.4, 0.0, 0.2, 1);
$easing--decelerated: cubic-bezier(0.0, 0.0, 0.2, 1);
$easing--in-out: cubic-bezier(0.4, 0.0, 0.6, 1);

// todo: remove
$button-size: 128px;
$button-size-s: 80px;

/* z-index hierarchy */
$z-index--overlay: 1;
$z-index--sidebar: 10;
$z-index--sidebar-overlay: 11;
$z-index--dialog: 200;
$z-index--modal: 300;
$z-index--notification: 400;

$side-panel-width: 400px;
$modal-width: 400px;